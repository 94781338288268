import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { KeysPipe } from './pipes/keys.pipe';
import { MapPipe } from './pipes/map.pipe';
import { FlatRatePipe } from './pipes/flat-rate.pipe';
import { RejectedUserPipe } from './pipes/rejected-user.pipe';
import { TrimmerPipe } from './pipes/trimmer.pipe';
import { CustomPercentPipe } from './pipes/custom-percent.pipe';
import { CurrencyEuroPipe } from './pipes/euro-currency';
import { InEuroCentCurrency } from './pipes/in-euro-cent-currency';
import { FormItemFieldComponent } from './components/form-item-field/form-item-field.component';
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { StatusComponent } from './components/status/status.component';
import { ImageComponent } from './components/image/image.component';
import { UniqueDirective } from './directives/validators/unique.directive';
import { PasswordDirective } from './directives/validators/password.directive';
import { RequiredDirective } from './directives/validators/required.directive';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation';
import { AlphaDashDirective } from './directives/validators/alpha-dash.directive';
import { DomainDirective } from './directives/validators/domain.directive';
import { EmailDirective } from './directives/validators/email.directive';
import { MaxDirective } from './directives/validators/max.directive';
import { MinDirective } from './directives/validators/min.directive';
import { NumberDirective } from './directives/validators/number.directive';
import { EqualValidator } from './directives/validators/equal-validator.directive';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { GermanDatePipe } from './pipes/german-date';
import { SafePipe } from './pipes/safe.pipe';
import { RequiredIfEmptyDirective } from './directives/validators/required-if-empty.directive';
import { PdfUploaderComponent } from './components/pdf-uploader/pdf-uploader.component';
import { CityPipe } from './pipes/city.pipe';
import { CheckRequiredDirective } from './directives/validators/check-required.directive';
import { CompareNumberDirective } from './directives/validators/compare-number.directive';
import { FlatRateDirective } from './directives/validators/flat-rate.directive';
import { PhoneDirective } from './directives/validators/phone.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SupplierSelectComponent } from './components/supplier-select/supplier-select.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { HttpConfigService } from '../core/services/http/http.service';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { DecimalMaskDirective } from './directives/decimal-mask.directive';
import { DownloadAssessorsDirective } from './directives/download-assessors.directive';
import { DecodeUriFilePipe } from './pipes/decode-uri-file.pipe';
import { CompanyColumnComponent } from './components/company/company-column.component';
import { ModalsModule } from '../modals/modals.module';
import { GermanDateTimePipe } from './pipes/german-date-time';
import { AuditsDetailComponent } from '../leasing-details/audits-detail/audits-detail.component';
import { FileSizeInMbPipe } from './pipes/file-size';
import { HeaderGeneratedCodeComponent } from './components/header-generated-code/header-generated-code.component';
import { StatusTabsComponent } from './table-utilities/status-tabs/status-tabs.component';
import { FormatStringPipe } from './pipes/format-string.pipe';
import { PopupDialogComponent  } from '../shared/components/popup-dialog/popup-dialog.component';
import { ProductTypePipe } from './pipes/product-type.pipe';

@NgModule({
    imports: [
        CommonModule,
        TooltipModule,
        MatCheckboxModule,
        ModalsModule,
    ],
    declarations: [
        SupplierSelectComponent,
        UniqueDirective,
        FormItemFieldComponent,
        ValidationErrorsComponent,
        StatusComponent,
        CompanyColumnComponent,
        KeysPipe,
        MapPipe,
        FlatRatePipe,
        RejectedUserPipe,
        TrimmerPipe,
        CustomPercentPipe,
        CurrencyEuroPipe,
        InEuroCentCurrency,
        GermanDatePipe,
        GermanDateTimePipe,
        CityPipe,
        ImageComponent,
        PasswordDirective,
        RequiredDirective,
        ClickStopPropagationDirective,
        AlphaDashDirective,
        DomainDirective,
        EmailDirective,
        MaxDirective,
        MinDirective,
        NumberDirective,
        CheckRequiredDirective,
        CompareNumberDirective,
        FlatRateDirective,
        PhoneDirective,
        EqualValidator,
        ImageUploaderComponent,
        PdfUploaderComponent,
        SafePipe,
        RequiredIfEmptyDirective,
        ReplacePipe,
        CurrencyMaskDirective,
        DecimalMaskDirective,
        ReplacePipe,
        DecodeUriFilePipe,
        DownloadAssessorsDirective,
        AuditsDetailComponent,
        FileSizeInMbPipe,
        HeaderGeneratedCodeComponent,
        StatusTabsComponent,
        FormatStringPipe,
        PopupDialogComponent,
        ProductTypePipe
    ],
    exports: [
        SupplierSelectComponent,
        UniqueDirective,
        FormItemFieldComponent,
        ValidationErrorsComponent,
        StatusComponent,
        CompanyColumnComponent,
        KeysPipe,
        MapPipe,
        FlatRatePipe,
        RejectedUserPipe,
        TrimmerPipe,
        CustomPercentPipe,
        CurrencyEuroPipe,
        InEuroCentCurrency,
        GermanDatePipe,
        GermanDateTimePipe,
        CityPipe,
        ImageComponent,
        PasswordDirective,
        RequiredDirective,
        ClickStopPropagationDirective,
        AlphaDashDirective,
        DomainDirective,
        EmailDirective,
        MaxDirective,
        MinDirective,
        PhoneDirective,
        NumberDirective,
        CheckRequiredDirective,
        CompareNumberDirective,
        FlatRateDirective,
        EqualValidator,
        ImageUploaderComponent,
        PdfUploaderComponent,
        SafePipe,
        RequiredIfEmptyDirective,
        ReplacePipe,
        CurrencyMaskDirective,
        ReplacePipe,
        DecodeUriFilePipe,
        DownloadAssessorsDirective,
        DecimalMaskDirective,
        AuditsDetailComponent,
        FileSizeInMbPipe,
        HeaderGeneratedCodeComponent,
        StatusTabsComponent,
        FormatStringPipe,
        PopupDialogComponent,
        ProductTypePipe
    ],
    providers: [
        HttpConfigService,
        CurrencyPipe,
        SafePipe,
    ]
})

export class SharedModule {}
