import { CompanyLeasingCondition } from './company-leasing-condition';
import { Dinero } from 'dinero.js';
import * as DineroFactory from 'dinero.js';
import { Status } from './status';
import { Model } from './model';
import { Mixin } from '../../common/mixin';
import { HasLeasingSettings } from './has-leasing-settings';
import { CompanyEditsPermissions } from './company-edits-permissions';
import { HasMany } from '../../core/relations/has-many';
import { User } from './user';
import { HasOne } from '../../core/relations/has-one';
import { LeasingCondition } from './leasing-condition';

export enum CompanyStatus {
    NotCreated = 0,
    Active = 5,
    Inactive = 6,
}

export enum CoverageTypes {
    FIXED = 'fixed',
    PERCENTAGE = 'percentage',
}

export enum InvoicingType {
    GROSS = 'gross',
    NET = 'net',
}

interface CompanyMixin
    extends Model,
        HasLeasingSettings,
        CompanyEditsPermissions {}

@HasMany(User, 'admins')
@HasMany(LeasingCondition, 'leasingConditions')
@HasOne(Status, 'status')
export class Company extends Mixin<CompanyMixin>(
    Model,
    HasLeasingSettings,
    CompanyEditsPermissions
) {
    public endpoint = 'companies';
    public id: number;
    public address: string;
    public admins: User[];
    public city: any;
    public code: string;
    public name: string;
    public zip: string | number;
    public vat: string = Company.NO_VAT_ID_VALUE;
    public invoice_type = 'net';
    public admin_first_name: string;
    public admin_last_name: string;
    public admin_email: string;
    public supplier_ids: Array<any> = [];
    public subcompany_ids: Array<any> = [];
    public city_id: string;
    public phone: string;
    public max_user_contracts: number = 2;
    public max_user_amount: number = 11900;
    public min_user_amount: number = 749;
    public insurance_covered = true;
    public insurance_covered_type = CoverageTypes.PERCENTAGE;
    public insurance_covered_amount: number = 100;
    public maintenance_covered = false;
    public maintenance_covered_type = CoverageTypes.FIXED;
    public maintenance_covered_amount: number;
    public leasing_rate = false;
    public leasing_rate_type = CoverageTypes.FIXED;
    public leasing_rate_amount: number;
    public leasing_budget: number;
    public slug: string;
    public status: Status;
    public logo: string | Blob;
    public color = '#f9a110';
    public manualContractApproval = true;
    public is_accept_employee = true;
    public s_pedelec_disable = false;
    public enable_bike_shop: boolean;
    public pecuniary_advantage = true;
    public end_contract: Date = new Date();
    public gross_conversion = 'brutto';
    public uses_default_subsidies = false;
    public include_service_rate = 1;
    public include_insurance_rate = 1;
    public boni_number: string;
    public gp_number: string;
    public parent_id: number;
    public parent_company_id: number;
    public show_percentage_discount = true;
    public required_personal_number = true;
    public required_register_by_domain = true;
    public product_category_ids: number[];
    public parent_company: Company;
    public block_leasing_rate = false;
    public block_insurance_rate = false;
    public block_service_rate = false;

    public static readonly NO_VAT_ID_VALUE: string = 'Nicht vorhanden';

    public isActive() {
        return this.status.id === CompanyStatus.Active;
    }

    public isLeasingCoverageApplied() {
        return !!this.leasing_rate;
    }

    public isLeasingCoverageType(coverageType: CoverageTypes) {
        return this.leasing_rate_type === coverageType;
    }

    public getLeasingCoverageAmount() {
        return this.leasing_rate_amount;
    }

    public isInsuranceCoverageApplied() {
        return !!this.insurance_covered;
    }

    public isInsuranceCoverageType(coverageType: CoverageTypes) {
        return this.insurance_covered_type === coverageType;
    }

    public getInsuranceCoverageAmount() {
        return this.insurance_covered_amount;
    }

    public isServiceCoverageApplied() {
        return !!this.maintenance_covered;
    }

    public isServiceCoverageType(coverageType: CoverageTypes) {
        return this.maintenance_covered_type === coverageType;
    }

    public getServiceCoverageAmount() {
        return this.maintenance_covered_amount;
    }

    public isEnabledBikeShop() {
        return this.enable_bike_shop;
    }

    public getLeasingSubsidyOf(leasingRate: Dinero): Dinero {
        if (this.isLeasingCoverageApplied()) {
            if (this.isLeasingCoverageType(CoverageTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getLeasingCoverageAmount() * 100),
                    currency: 'EUR',
                });
            } else {
                const leasingRateCoverageRatio =
                    this.getLeasingCoverageAmount() / 100;
                return leasingRate.multiply(leasingRateCoverageRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }

    public getInsuranceSubsidyOf(insuranceRate: Dinero): Dinero {
        if (this.isInsuranceCoverageApplied()) {
            if (this.isInsuranceCoverageType(CoverageTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getInsuranceCoverageAmount() * 100),
                    currency: 'EUR',
                });
            } else {
                const insuranceCoverageRatio =
                    this.getInsuranceCoverageAmount() / 100;
                return insuranceRate.multiply(insuranceCoverageRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }

    public getServiceSubsidyOf(serviceRate: Dinero): Dinero {
        if (this.isServiceCoverageApplied()) {
            if (this.isServiceCoverageType(CoverageTypes.FIXED)) {
                return DineroFactory({
                    amount: Math.round(this.getServiceCoverageAmount() * 100),
                    currency: 'EUR',
                });
            } else {
                const serviceCoverageRatio =
                    this.getServiceCoverageAmount() / 100;
                return serviceRate.multiply(serviceCoverageRatio);
            }
        } else {
            return DineroFactory({ amount: 0, currency: 'EUR' });
        }
    }
}
